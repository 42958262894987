import PrivacyHtml from "../../components/PrivacyHtml";

const Privacy = () => {
  return (
    <section className="privacy">
      <PrivacyHtml/>
    </section>
  );
};

export default Privacy;
